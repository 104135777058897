import React, { useEffect } from 'react';
import BlogClassicData from '../data/blog/BlogListt.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import { useParams } from 'react-router-dom'; // useParams eklemeyi unutmayın
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';


function BlogDetails ()  {

    

    const { id } = useParams(); // useParams ile id'yi alın
    const blogId = parseInt(id, 10);
    const data = BlogClassicData.blog.filter(blog => blog.id === blogId);

    return (
        <>
            <SEO title="BlogDetay" />
            <div>
            <HeaderTopBar />
            <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
          </div>
          
                <div className="rn-blog-details-area">
                    <BlogDetailsContent id={id} />
                </div>

        </>
    );
};

export default BlogDetails;
