import React from 'react'
     /*<div className="rn-gradient-circle theme-pink"></div>*/
const Blury = () => {
    return (
        <div>
            <div className="rn-gradient-circle"></div>
       
        </div>
    )
}

export default Blury
