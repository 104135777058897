import React, { useEffect } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import ElementsBig from"../elements/gallery/GalleryBig";


function GallerPage  ()  {

    return (
        <>
            <SEO title="Servisler" />
            <main className="page-wrapper">
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>
 
  
            {/* End Slider Area  */}
               {/* Start Service Area  */}
               <div className="rn-service-area"style={{ paddingTop: '80px' }}>
               <div className="container">
                   <div className="row">
                       <ElementsBig></ElementsBig>
                   </div>
                 
           
               </div>
               </div>
    

               <FooterFour/>
               </main>
        </>
    )
}

export default GallerPage;
